
import Vue from "vue";
// import UploadArea from "@/components/UploadArea/UploadArea.vue";
interface ICompanyCompanyData {
  errorMessage: string;
  isLoading: boolean;
  successMessage: string;
  data: any;
  hideUploadArea: boolean;
}
export default Vue.extend({
  name: "UploadAgentReports",
  components: {
    // UploadArea,
    UploadedReports: () =>
      import("@/components/AgentReportsPortal/UploadedFiles.vue")
  },
  data(): ICompanyCompanyData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      data: [],
      hideUploadArea: false
    };
  },
  methods: {
    onFileUploadError(err: any) {
      this.errorMessage = err.message;
    },
    async onFileUploadSuccess() {
      this.$appNotifySuccess(
        "File Upload Success. Check back after 3 minutes."
      );
      // this.hideUploadArea = true;
      this.$router.push(`/agent-reports`);
    }
  },
  computed: {}
});
